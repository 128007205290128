<template>
  <div class="container">
    <div class="row justify-content-center">
      <div
        class="
          col-12 col-md-7 col-lg-5 col-xl-4
          my-5
          card
          light-shadow
          p-5
          forgot-box-container
        "
      >
        <h1 class="display-4 text-center mb-3">Reset Password</h1>
        <form
          name="ForgotPasswordForm"
          autocomplete="off"
          validate
          @submit.prevent="forgotPassword"
          method="post"
        >
          <input-control
            v-model="email"
            id="email"
            control="email"
            :required="true"
          >
            Email Address
          </input-control>
          <save
            :saving="auth.sendingEmail"
            classes="btn btn-lg btn-block btn-black mb-3"
          >
            Send Reset Link
          </save>
          <div class="text-center">
            <button class="btn btn-link" @click.prevent="goBack()">Back</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";

export default {
  name: "ForgotPasswordForm",
  computed: {
    ...mapState(["auth"]),
  },
  data() {
    return {
      email: null,
    };
  },
  methods: {
    ...mapActions({
      requestForgotPassword: "auth/forgotPassword",
    }),
    async forgotPassword() {
      try {
        await this.requestForgotPassword({
          email: this.email,
        });
        Swal.fire({
          title: "Check your email",
          text: "We have emailed your password reset link!",
          icon: "",
        });
        this.$router.push({ name: "login" });
      } catch (err) {
        Swal.fire({
          title: "Send Reset Link Failed",
          text: ((err.data || {}).error || err.error || {}).message,
          icon: "error",
        });
      }
    },
    goBack() {
      window.history.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
@media (max-width: 767px) {
  .forgot-box-container {
    max-width: 90%;
  }
}
</style>
